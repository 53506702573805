import React from 'react';
import gallery1 from '../../assets/images/gallery.jpg';
import gallery2 from '../../assets/images/gallery1.jpg';
import gallery3 from '../../assets/images/gallery2.jpg';
import gallery4 from '../../assets/images/gallery3.jpg';
import gallery5 from '../../assets/images/gallery4.jpg';

const images = [gallery2, gallery3, gallery4, gallery5];

const Gallery = () => {
  return (
    <section className='gallery-one'>
      {images.map((image, index) => (
        <div className='Gallery' key={index}>
          <img
            style={{
              width: '100%',
            }}
            src={image}
            alt={`Gallery ${index + 1}`}
          />
        </div>
      ))}
    </section>
  );
};

export default Gallery;
